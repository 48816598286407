import React, { useState } from 'react';
import Modal from 'react-modal';

const Footer = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const openVideoModal = () => {
        setModalOpen(true);
    };

    const closeVideoModal = () => {
        setModalOpen(false);
    };
    return (
        <div className='footer'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <p className=''>
                            <a href='/privacy' target="_blank" className='font-20 text-left text-black text-decoration-none'>Privacy Policy</a>
                            <a href='/term' target="_blank" className='font-20 ms-4 text-black text-decoration-none'>Terms & Conditions</a>
                        </p>
                        <div className='social-media mt-4'>
                            <a href="https://www.linkedin.com/company/inclusional/" target="_blank" className='social-link'>
                                <img src="/images/linkedin.png" className='social-icons'></img>
                            </a>
                            <a href="https://twitter.com/_inclusional/" target="_blank" className='social-link'>
                                <img src="/images/twitter.png" className='social-icons'></img>
                            </a>
                            <a href="https://www.instagram.com/inclusional/" target="_blank" className='social-link'>
                                <img src="/images/instagram.png" className='social-icons'></img>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100095283414576&amp;is_tour_dismissed=true" target="_blank" className='social-link'>
                                <img src="/images/facebook.png" className='social-icons'></img>
                            </a>
                        </div>
                        <p className='mt-4 mb-0 footer-copyright'>©Inclusional. All Rights Reserved 2024</p>
                    </div>

                    <div className='col-md-3'>
                        <p className='font-20 fw-bold ps-lg-3 ms-lg-3 ps-0 ms-0'>Contact Us</p>
                        <a href="mailto:support@inclusional.com" className="font-20 ps-lg-3 ms-lg-3 ps-0 ms-0 text-black text-decoration-none">
                            <img src="/images/email.png" className="footer-email" />
                            support@inclusional.com
                        </a>
                    </div>

                    <div className='col-md-2'>
                        <p className='font-20 fw-bold'>Quick Link</p>
                        <button onClick={openVideoModal} className='btn btn-link font-20 text-black bg-white p-0 text-decoration-none'>Watch Tutorial</button>
                        <Modal
                            isOpen={isModalOpen}
                            onRequestClose={closeVideoModal}
                            contentLabel="Video Modal"
                            className='videoModal w-75'
                        > <button type="button" className="close text-white" onClick={closeVideoModal}> <svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
                            <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
                        </svg></button>
                            <video autoPlay className="w-100" controls controlsList="nodownload">
                                <source src="https://inclusional.s3.ap-south-1.amazonaws.com/project_dev/project_dev/videos/2024-02-06 12:37:30-VID-20240123-WA0001.mp4" type="video/mp4" />
                            </video>
                        </Modal>
                    </div>

                    <div className='col-md-4'>
                        <img src="/images/footer-logo.png" className='footer-logo'></img>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default Footer;